<template>
    <v-bottom-navigation
        v-model="value"
        class="notifications__nav"
        color="primary"
        grow
    >
        <v-btn value="not" to="/notifications/page">
            <v-icon>mdi-bell</v-icon>

            <span>Notifications</span>
        </v-btn>
        <v-btn value="logs" to="/notifications/logs">
            <v-icon>mdi-history</v-icon>

            <span>Logs</span>
        </v-btn>
    </v-bottom-navigation>
</template>
<script>

export default {
    data() {
        return {
            value: 'not',
        };
    },
};
</script>

