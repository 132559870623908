
import Vue from 'vue';
import { debounce } from 'lodash';

import LogItem from './LogItem.vue';
import NotificationsNav from './NotificationsNav.vue';

import { logsActions } from '@/store/modules/log/names';

import { mapState, mapActions } from 'vuex';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'Logs',

    components: {
        NotificationsNav,
        LogItem,
    },

    data() {
        return {
            logsOptions: {
                created_at_from: '',
                created_at_to: '',
                search: '',
                page: 1,
                size: 10,
            },
            dates: [] as string[],
            dialog: false,
            searchInput: '',
            debouncedUpdateSearch: null,
            previousLogsOptions: {},
        };
    },

    computed: {
        ...mapState<ComposedState>({
            logs: (state: ComposedState) => state.logs.notificationsLogs,
            logsTotal: (state:ComposedState) => state.logs.notificationsTotal,
        }),

        dateRangeText(): string {
            return this.dates.join(' ~ ');
        },

        total(): number {
            // @ts-ignore
            return this.logsTotal;
        },

        notificationLogs(): any[] {

            // @ts-ignore
            return this.logs;
        },
    },

    watch: {
        logsOptions: {
            deep: true,
            handler(newOptions) {
                let isChanged = false;

                for (const key in newOptions) {
                    if (key === 'page') continue;
                    if (newOptions[key] !== this.previousLogsOptions[key]) {
                        isChanged = true;
                        break;
                    }
                }

                if (isChanged) {
                    this.logsOptions.page = 1;
                    this.previousLogsOptions = { ...newOptions };
                    this.getNotificationsLogsApi(this.logsOptions);
                    return;
                }

                // @ts-ignore
                if (newOptions.page !== this.previousLogsOptions.page) {

                    // @ts-ignore
                    this.previousLogsOptions.page = newOptions.page;
                    this.getNotificationsLogsApi(this.logsOptions);
                }
            },
        },

        searchInput: {
            handler(newSearch) {
                if (newSearch.length >= 3 || newSearch === '') {

                    // @ts-ignore
                    this.debouncedUpdateSearch(newSearch);
                }
            },
        },
    },

    created() {
        // @ts-ignore
        this.debouncedUpdateSearch = debounce((searchValue: string) => {
            this.logsOptions.search = searchValue;
        }, 300);
    },

    async mounted() {
        if (!this.logsOptions.created_at_from) {
            let today: Date | string = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();

            today = `${yyyy}-${mm}-${dd}T00:00:00.000000`;

            this.logsOptions.created_at_from = '2000-01-01T00:00:00.000000';
            this.logsOptions.created_at_to = '2050-01-01T00:00:00.000000';
        }
    },

    beforeDestroy() {
        if (this.debouncedUpdateSearch) {
            // @ts-ignore
            this.debouncedUpdateSearch.cancel();
        }
    },

    methods: {
        ...mapActions({
            getNotificationsLogs: logsActions.getNotificationsLogs,
        }),

        dateClick(): void {
            this.dialog = false; 
            let fromDate = `${this.dates[0]}T00:00:00.000000`;
            let toDate = this.dates.length === 2 
                ? `${this.dates[1]}T23:59:59.000000` 
                : '2030-01-01T23:59:59.000000';

            if (new Date(fromDate) > new Date(toDate)) {
                [fromDate, toDate] = [
                    `${this.dates[1]}T00:00:00.000000`, 
                    `${this.dates[0]}T23:59:59.000000`,
                ];
            }

            this.logsOptions.created_at_from = fromDate;
            this.logsOptions.created_at_to = toDate;
        },

        async getNotificationsLogsApi(params) {
            this.previousLogsOptions = { ...params },
            await this.getNotificationsLogs(params);
        },
    },
});
