
import Vue, { PropType } from 'vue';
import { IRecipient } from '@/store/modules/log/types';

export default Vue.extend({
    name: 'Notifications',

    props: {
        recipients: {
            required: true,
            type: Array as PropType<IRecipient[]>,
        },
        openOnModal: {
            type: Boolean,
        },
        canClose: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        recipientsBackground(): string[] {
            return this.recipients.map((recipient: IRecipient) => {
                switch (true) {
                    case !recipient.is_recognized && !recipient.is_active && !recipient.is_valid:
                        return '#ff2400';
                    case recipient.is_recognized && !recipient.is_active && recipient.is_valid:
                        return '#ffff00';
                    case !recipient.is_recognized && !recipient.is_active && recipient.is_valid:
                        return '#ffa500';
                    case recipient.is_recognized && recipient.is_active && recipient.is_valid:
                        return '#00ff00';
                    default:
                        return '';
                }
            });
        },
    },
});
