
import Vue from 'vue';
import moment from 'moment';

import RecipientsList from './RecipientsList.vue';

export default Vue.extend({
    name: 'LogItem',

    components: {
        RecipientsList,
    },

    props: {
        log: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            visibleRecipientsLimit: 8,
            isShowMoreModalOpen: false,
        };
    },

    computed: {
        formatedDate(): string {
            return moment(this.log.created_at).format('YYYY-MM-DD HH:mm:ss');
        },
    },

    methods: {
        downloadFile() {
            const link = document.createElement('a');
            link.href = this.log.url;
            link.click();
        },
    },
});
